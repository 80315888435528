import _ from "lodash";
import { fileUrl } from "reducers/api";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { scores } from "views/init";
import noPhoto from "assets/images/no-photo.png";

import NumberFormat from "react-number-format";

// формат номера телефона
export const phoneFormat = "# (###) ###-####";

export const unufiedNewLine = (text) => {
  return String(text).replace(/\n+/g, "\n").replace(/\n/g, "\n\n").trim();
};

export const emojies = ["🐰", "🦊", "🐻", "🐼", "🐨", "🐯", "🦁", "🐵", "🐶", "🐹", "🐺", "🐴"];

export const showMoney = (value, symbol = "₽") => (
  <NumberFormat
    displayType="text"
    suffix={` ${symbol}`}
    thousandSeparator={" "}
    decimalSeparator={"."}
    decimalScale={2}
    allowNegative={false}
    value={value}
  />
);

export const showDigits = (value) => (
  <NumberFormat
    displayType="text"
    thousandSeparator={" "}
    decimalScale={0}
    allowNegative={false}
    value={value}
    className="text-nowrap"
  />
);

export const scrollTop = () => window.scrollTo(0, 0);

// Hook
export function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export const PayIcons = (props) => (
  <div className="text-muted" style={{ fontSize: "2.3em" }}>
    {/* <i className="fab fa-cc-visa mr-1" /> */}
    {/* <i className="fab fa-cc-mastercard mr-1" /> */}
    {/* <i className="fab fa-cc-apple-pay mr-1" /> */}
    <i className="fab fa-apple-pay mr-3" />
    <i className="fab fa-google-pay" />
  </div>
);

export const safeScrollIntoView = (element, extraOffset = 0) => {
  setTimeout(() => {
    const { offsetTop } = element;
    const header = document.getElementById("sticky-header");
    const { offsetHeight } = header;
    const { top } = header.style;
    const headerTop = +top.substr(0, top.length - 2);
    const resultTop = offsetTop + extraOffset - (offsetHeight + headerTop);
    window.scrollTo({
      top: resultTop,
    });
  }, 300);
};

export const modalOpenFix = (isOpen) => {
  // isOpen && (document.body.style.overflow = "hidden");
  // !isOpen && (document.body.style.overflow = "unset");
};

export const counterValue = (value) => {
  return value > 9 ? "9+" : value;
};

export const LoadingIcon = (props) => {
  return <i className={`fa fa-spinner fa-spin ${props.className}`} />;
};

export const backgroundCover = (url) => {
  return {
    backgroundImage: `url(${url})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
};

// превратить идентификаторы фотографий в ссылки
export const categoryPhotosUrl = (category, type) => {
  // источник фотографий - рекламные или системные
  const source = _.size(category.filesId) ? category.filesId : [category.avatarFileId];

  const photos = _.filter(
    _.map(source, (fileId) => fileUrl(fileId, type)),
    _.size,
  );
  return photos.length ? [...photos] : [noPhoto];
};

export const AvgScoreStars = (avgScore) => {
  return (
    <>
      <span className="mr-2">{avgScore}</span>
      {_.map(scores, (score) => {
        let className;
        if (avgScore > score - 0.5) {
          className = "fas fa-star";
        } else if (avgScore <= score - 0.5 && avgScore > score - 1) {
          className = "fas fa-star-half-alt";
        } else {
          className = "fal fa-star";
        }
        return <i className={`${className} mr-1`} style={{ color: "gold" }} key={score} />;
      })}
    </>
  );
};

export const ShowPager = (props) => {
  const { total, current, swiper } = props;

  return (
    <span style={{ fontSize: ".5em" }}>
      {_.range(0, total).map((index) => {
        return (
          <i
            key={index}
            className={`fa fa-circle px-1 pager-point ${index === current ? "active" : ""}`}
            onClick={() => swiper.slideTo(index)}
            style={{ cursor: "pointer" }}
          />
        );
      })}
    </span>
  );
};

export const SmartImage = (props) => {
  const [id, setId] = useState(`smart_image_${Math.random().toString().substring(3, 30)}`);
  useEffect(() => {
    const thisImage = document.getElementById(id);
    const onError = () => {
      thisImage.removeEventListener("error", onError);
      thisImage.src = `${props.src}?dropcache=${Date.now()}`;
    };
    thisImage.addEventListener("error", onError);
  }, []);
  return <img id={id} {...props} />;
};

export const LogoImage = () => {
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env.www || {};

  return (
    <SmartImage
      src={fileUrl(settings.logoImageId)}
      style={{ width: "35px", height: "35px", marginTop: "5px" }}
      className="rounded rounded-circle"
    />
  );
};
