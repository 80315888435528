import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { useTranslate } from "react-redux-multilingual";

const NextDay = (props) => {
  const { nextDay, isVisible } = props;
  const translate = useTranslate();

  const [time, setTime] = useState("");

  const timeoutStore = useRef(false);
  useEffect(() => {
    return () => {
      clearTimeout(timeoutStore.current);
    };
  }, []);

  // на случай, если вдруг обновились данные о времени, перезапустим счетчик
  useEffect(() => {
    clearTimeout(timeoutStore.current);
    props.isVisible && updateTime(new Date());
  }, [props]);

  const updateTime = (currentTime) => {
    const endTime = dayjs(nextDay);
    let hours = dayjs.duration(endTime.diff(currentTime)).hours();
    let minutes = dayjs.duration(endTime.diff(currentTime)).minutes();
    let seconds = dayjs.duration(endTime.diff(currentTime)).seconds();

    if (seconds < 0) {
      document.location.reload();
      return;
    }

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    setTime([hours, minutes, seconds].join(":"));
    timeoutStore.current = setTimeout(() => {
      updateTime(new Date());
    }, 1000);
  };

  // специальный ограничитель, чтобы не показывать таймер, пока грузится информация о заказе
  // на случай, если вернулись в него после оплаты, но заметно позже истекшего времени
  return (
    <div className={`${props.className}`}>
      {translate("next_day")}
      <div className="large-header">{time}</div>
      {/* {props.titleText}: {time} */}
    </div>
  );
};

export default NextDay;
