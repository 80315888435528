import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { useTranslate } from "react-redux-multilingual";

import LocalesValues from "components/standart/LocalesValues";
import GiphyImage from "components/standart/giphyImage";
import ShareButton, { contentId } from "components/standart/share-button";

const ShareContent = (props) => {
  const translate = useTranslate();
  const { changeValueHandler } = props;
  const sharedData = { ...props.sharedData?.content };
  const { customer } = { ...props.sharedData };
  useEffect(() => {
    props.changeMeta({
      title: translate("your_horoscope"),
    });
  }, [props.sharedData]);
  return (
    <div>
      <div className="mb-3 large-header">
        {/* {translate("your_day", customer)} */}
        {translate("your_horoscope")}
      </div>
      <GiphyImage {...sharedData.imageData} />
      <div className="py-2" />
      <div>
        <div className="content-html">
          <LocalesValues
            values={sharedData.dailyQuote.localesText}
            changeValueHandler={changeValueHandler}
            isHtml={true}
          />
        </div>
        <div className="mt-2 hashTag text-lowercase">
          {`#`}
          {/* <LocalesValues values={sharedData.dailyQuoteCategory?.localesName} /> */}
          <LocalesValues values={sharedData.dailyQuoteTopic?.localesName} />
        </div>
      </div>
    </div>
  );
};

export default connect()(ShareContent);
