import React from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { useTranslate } from "react-redux-multilingual";

const ShowToday = (props) => {
  const { sessionStore } = props;
  const translate = useTranslate();
  const [dayOfWeek, date, month] = dayjs(sessionStore).format("E-D-M").split("-");
  return <>{`${translate('today')} ${translate(`day_${dayOfWeek}`)}, ${date} ${translate(`month_rod_${month}`)}.`}</>;
};

const mapeStateToProps = (state) => ({
  sessionStore: state.customer.session,
});

export default connect(mapeStateToProps, {})(ShowToday);
