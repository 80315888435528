import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { scrollTop, ShowPager } from "components/standart";
import GiphyImage from "components/standart/giphyImage";
import LoadingModal from "components/standart/loading-modal";
import ShareButton, { contentId } from "components/standart/share-button";
import LocalesValues from "components/standart/LocalesValues";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";

import { customerApi } from "reducers/api";
import FriendsBlock from "./friends";
import AboutBlock from "./aboutBlock";
import LoveBlock from "./loveBlock";
import MonthBlock from "./monthBlock";
import NextDay from "./nextDay";
import TodayScore from "./todayScore";

const { api } = customerApi.actions;
SwiperCore.use([Pagination]);

const DataBlock = (props) => {
  const translate = useTranslate();
  const { sessionStore } = props;
  const [content, setContent] = useState(null);
  const [currentDaily, setCurrentDaily] = useState(null);
  const [swiper, setSwiper] = useState(null);

  const [baseLoaded, setBaseLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideChangeHandler = (event) => {
    setCurrentIndex(event.realIndex);
    return true;
  };

  useEffect(() => {
    content && setCurrentDaily(content.dailyContents[currentIndex]);
  }, [content, currentIndex]);

  const loadContent = () => {
    props.getContent(
      {},
      {
        onSuccess(body) {
          setContent(body);
          setIsLoaded(false);
        },
        onRequest() {
          setIsLoaded(true);
        },
      },
    );
  };
  const loadingTimeout = useRef(false);

  const totalTimeout = 7000;
  useEffect(() => {
    if (isLoaded && !baseLoaded) {
      loadingTimeout.current = setTimeout(() => {
        setBaseLoaded(true);
      }, totalTimeout);
    }
  }, [isLoaded]);

  useEffect(() => {
    scrollTop();
    return () => {
      clearTimeout(loadingTimeout.current);
    };
  }, []);

  useEffect(() => {
    (baseLoaded || sessionStore.account?.birthDate) && loadContent();
  }, [sessionStore.account, baseLoaded]);

  return !baseLoaded ? (
    <div className="wtf">
      <LoadingModal {...sessionStore} totalTimeout={totalTimeout} />
    </div>
  ) : (
    <>
      {content ? (
        <section className="bg-white pt-0">
          <div className="container">
            <div className="row">
              <div className="col"></div>
              <div className="col-12 col-md-6 -col-lg-4 text-center">
                <div className="mb-3 pt-3 large-header">
                  {/* {translate("your_day", { ...sessionStore.account })} */}
                  {translate("your_horoscope")}
                </div>
                {/* 
                <span>
                  {sessionStore.account.name}
                  {", "}
                  {translate("about_you")}{" "}
                  <span className="hashTag">
                    {"#"}
                    {translate(`zodiac_${sessionStore.astroSign}`).toLowerCase()}
                  </span>
                  {", "}
                  {translate("your_day_today", {
                    date: dayjs().format("DD.MM.YYYY"),
                  })}
                </span>
                 */}

                <div className="position-relative">
                  <div
                    className="px-1 py-0 rounded standart-header"
                    style={{
                      position: "absolute",
                      zIndex: 10,
                      top: 0,
                      right: 0,
                      backgroundColor: "rgba(0, 0, 0, .38)",
                      color: "white",
                      fontSize: "0.9rem",
                    }}
                  >
                    {`${currentIndex + 1}/${content.dailyContents.length + 1}`}
                  </div>

                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    pagination={false}
                    onSwiper={setSwiper}
                    onSlideChange={slideChangeHandler}
                  >
                    {content.dailyContents.map((dailyContent, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div>
                            <div>
                              <GiphyImage {...dailyContent.imageData} />
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                    <SwiperSlide>
                      <div className="giphy-image pt-5">
                        <TodayScore {...content} refreshContent={loadContent} />
                        <NextDay
                          {...sessionStore}
                          isVisible={currentIndex === content.dailyContents.length}
                          className="mt-3"
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>

                  <div className="py-2">
                    <ShowPager
                      total={content.dailyContents.length + 1}
                      current={currentIndex}
                      swiper={swiper}
                    />
                  </div>

                  {currentDaily && (
                    <div>
                      <div>
                        <div className="content-html">
                          <LocalesValues
                            values={currentDaily.dailyQuote.localesText}
                            isHtml={true}
                          />
                          {/* {ReactHtmlParser(currentDaily.dailyQuote.text)} */}
                        </div>
                        <div className="mt-2 hashTag text-lowercase">
                          {`#`}
                          {/* 
                          <LocalesValues
                            values={currentDaily.dailyQuoteCategory?.localesName}
                            defaultValue={currentDaily.dailyQuoteCategory?.name}
                          />
                           */}
                          <LocalesValues
                            values={currentDaily.dailyQuoteTopic?.localesName}
                            defaultValue={currentDaily.dailyQuoteTopic?.name}
                          />
                        </div>
                      </div>

                      <div className="mt-5">
                        <ShareButton share={contentId} currentId={currentDaily._id} />
                        {/* <ShowShare currentId={currentDaily._id} /> */}
                      </div>
                    </div>
                  )}

                  <div className="py-5" />
                  <FriendsBlock {...content} />
                  <div className="py-5" />

                  <AboutBlock />
                  <div className="py-3" />
                  <MonthBlock />
                  {/* <div className="py-3" />
                  <LoveBlock /> */}
                  <div className="py-4" />
                  <div className="py-3" />
                </div>
              </div>
              <div className="col"></div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};


const mapStateToProps = (state) => ({
  env: state.frontend.env,
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {
  getContent: api.get("/account/content"),
})(DataBlock);
