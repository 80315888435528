// whatsapp message
export const SET_WHATSAPP_MESSAGE = "SET_WHATSAPP_MESSAGE";

// locales selector
export const SET_LOCALE_DATA = "SET_LOCALE_DATA";
export const SET_LOCALE_COUNTRY = "SET_LOCALE_COUNTRY";

// indicators
export const SET_INDICATOR_FRIENDS_OPENED = "SET_INDICATOR_FRIENDS_OPENED";

// navigator
export const SET_FRIENDS_REFRESH = "SET_FRIENDS_REFRESH";
export const SET_NAVI = "SET_NAVI";
export const RESET_NAVI = "RESET_NAVI";
export const SET_HEADER_HEIGHT = "SET_HEADER_HEIGHT";
export const SET_BOTTOM_HEIGHT = "SET_BOTTOM_HEIGHT";
export const SET_SHOPFILTER_OPEN = "SET_SHOPFILTER_OPEN";
export const SET_SHOPFILTER_OPTIONS = "SET_SHOPFILTER_OPTIONS";
