import _ from "lodash";
import apiClass from "./init";

import { accessToken } from "./index";
import session from "./session";
import api from "./api";

const auth = new apiClass();

auth.actions = {
  requestCode: auth.post("/auth/request"),
  validateCode: auth.post("/auth/confirm", { success: (state) => state }),
  telegramAuth: auth.post("/auth/telegram", { success: (state) => state }),
};

export const middleware = (store) => (next) => (action) => {
  const result = next(action);
  // console.log("action.type", action.type);
  // /*
  const requestValidate = auth.url_states["/auth/request"].ON_SUCCESS;
  const successValidate = auth.url_states["/auth/confirm"].ON_SUCCESS;
  const successTelegram = auth.url_states["/auth/telegram"].ON_SUCCESS;
  const changePhoneValidate = _.get(api.url_states, ["/account/phone/confirm", "ON_SUCCESS"]);

  const [method, key, uniq] = _.split(action.type, "|");
  const methodKey = [method, key].join("|");
  // делаем поиск по совпадениям фрагментов ссылок
  switch (methodKey) {
    case successValidate:
    case changePhoneValidate:
    case requestValidate:
    case successTelegram:
      // case successValidate:
      // case changePhoneValidate:
      // case requestValidate:
      // case successTelegram:
      // собственный middleware при успешном получении токенов
      const { type = "", accessToken: token = "" } = { ...action.payload };
      if (token) {
        // записать его в локальное хранилище
        localStorage.setItem(accessToken, `${type} ${token}`);

        // success  процедура при успешном получении информации о пользователе
        const onSuccess = ({ body }) => {};

        // получить информацию о пользователе
        store.dispatch(session.actions.getUser({}, { onSuccess }));
      }
      break;
    default:
      break;
  }
  // */
  return result;
};

export default auth;
