import _ from "lodash";
import { connect, useSelector } from "react-redux";
import dayjs from "dayjs";
import React, { useState, useEffect, useRef } from "react";
import { useTranslate } from "react-redux-multilingual";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import WebAppUserpic from "components/standart/webapp-userpic";
import { fileUrl } from "reducers/api";
import WebApp from "@twa-dev/sdk";

export const LockScreenModal = (props) => {
  const { setTimeoutComplete, totalTimeout } = props;

  const { account } = props.sessionStore;

  const translate = useTranslate();
  const dateFormat = translate("dateFormat");
  const [helpText, setHelpText] = useState("preparing_calculation");
  const { settings = {} } = props.env.www || {};

  const timeoutChangeTitle = useRef(false);
  const timeoutComplete = useRef(false);

  const runVibration = () => {
    const vibrations = ["light", "medium", "heavy", "rigid", "soft"];
    _.range(0, 15).forEach((value) => {
      setTimeout(() => {
        const [vibration] = _.shuffle(vibrations);
        WebApp.HapticFeedback.impactOccurred(vibration);
      }, value * 50);
      // WebApp.HapticFeedback.impactOccurred('medium');
      // WebApp.HapticFeedback.impactOccurred('medium');
      // WebApp.HapticFeedback.impactOccurred('medium');
      // WebApp.HapticFeedback.impactOccurred('medium');
    });
  };

  useEffect(() => {
    runVibration();
    setTimeout(() => {
      runVibration();
      setHelpText("loading_calculation");
    }, totalTimeout / 3);

    setTimeout(() => {
      runVibration();
      setHelpText("advise_preparing");
    }, (totalTimeout * 2) / 3);

    setTimeout(() => {
      runVibration();
      setTimeoutComplete(true);
    }, totalTimeout);
  }, []);

  return account.name ? (
    <Modal size="md" centered={true} wrapClassName="fullscreen-modal" isOpen={true}>
      <ModalHeader></ModalHeader>
      <ModalBody>
        <div className="text-center pt-0">
          <div className="position-relative">
            <div style={{ height: "230px", fontSize: "230px" }}>
              {/* <i className="fa fa-sync fa-spin" /> */}
              <i className="fa fal fa-spinner-third fa-spin"></i>
            </div>
            <div
              style={{
                position: "absolute",
                marginLeft: "auto",
                marginRight: "auto",
                top: 82,
                left: 0,
                right: 0,
                textAlign: "center",
              }}
            >
              <WebAppUserpic size={180} src={fileUrl(settings.logoImageId)} noPremium={true} />
            </div>
          </div>
          <div className="large-header pt-5 mt-5">
            {translate("loading_hello", { name: _.first(account.name.split(" ")) })}
          </div>
          {/* 
          <div className="py-2" />
          <div>
            {translate(`About_you`)}
            <span className="hashTag mt-4">
              {" #"}
              {translate(`zodiac_${astroSign}`).toLowerCase()}
            </span>
          </div>
          */}
          <div className="pt-3" />
          <div>{translate(helpText, { today: dayjs().format(dateFormat) })}</div>
          <div className="pt-3" />
          <div className="hashTag">v {settings.versionNumber}</div>
        </div>
      </ModalBody>
    </Modal>
  ) : null;
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {})(LockScreenModal);
