import _ from "lodash";
import React, { useEffect, useState, useRef, useCallback } from "react";

import { connect, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { LoadingIcon, scrollTop, ShowPager, unufiedNewLine } from "components/standart";
import GiphyImage from "components/standart/giphyImage";
import LoadingModal from "components/standart/loading-modal";
// import ShareButton, { contentId } from "components/standart/share-button";
import WebAppShareButton, { contentId } from "components/standart/webapp-share-button";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";
import Typewriter from "typewriter-effect";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import MemeImage from "components/standart/memeImage";
import ShowToday from "components/standart/showToday";
import nl2br from "react-nl2br";
import WebApp from "@twa-dev/sdk";
import Col3Container from "components/standart/col3Container";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";

import { customerApi } from "reducers/api";

import NextDay from "../data/nextDay";
import PremiumBlock from "../data/premium";
import { FriendZodiac } from "../data/friends/friendsDataElements";
import PeriodicContent from "../data/periodicContent";
import ExtraInfoBlock from "../data/extraInfoBlock";
import { PlanetAspectElement } from "../data/planetAspect";
// import TodayScore from "./todayScore";

const { api } = customerApi.actions;
SwiperCore.use([Pagination]);

const AspectsBlock = (props) => {
  const translate = useTranslate();
  const { sessionStore } = props;

  const { locales } = useSelector((state) => state);
  const [dailyContents, setDailyContents] = useState([]);
  const [currentDaily, setCurrentDaily] = useState(null);
  const [noPremium, setNoPremium] = useState(true);
  const [currentDailyText, setCurrentDailyText] = useState("");
  const [fullDailyText, setFullDailyText] = useState("");
  const [swiper, setSwiper] = useState(null);

  const [baseLoaded, setBaseLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [planetAspectData, setPlanetAspectData] = useState(null);
  const [lastLoaded, setLastLoaded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideChangeHandler = (event) => {
    setCurrentIndex(event.realIndex);
    return true;
  };

  useEffect(() => {
    dailyContents.length && setCurrentDaily(dailyContents[currentIndex]);
  }, [lastLoaded, currentIndex]);

  useEffect(() => {
    setNoPremium(!sessionStore.premium);
    if (currentDaily) {
      const { planet } = currentDaily.aspectCategory;
      const { aspectValue } = currentDaily.aspectDate;
      setPlanetAspectData({ aspectValue, planet });
      /*
      setAspectPlanet(_.toLower(translate(`planets_${planet}`)));
      if (aspectValue) {
        const { icon } = aspectValue;
        const aspect = getLocaledValue(aspectValue.localesName, locales.data?._id);
        setAspectText(translate("aspect_text", { icon, aspect }));
        // translate("moon_aspect_text", {
        //   icon,
        //   aspect: translate(`aspects_pred_${usedAspectValue.astroAspect}`),
        //   planet: translate(`planets_tvor_${planet}`),
        // }));
      } else {
        // setAspectText(translate("moon_aspect_default", { planet: translate(`planets_${planet}`) }));
        setAspectText(translate("aspect_default"));
      }
      */

      // const text = getLocaledValue(currentDaily.aspectQuote.localesText, locales.data?._id);
      const text = getLocaledValue(currentDaily.aspectQuote.localesText, locales.data?._id);
      // const dailyText = getLocaledValue(
      //   currentDaily.aspectQuote.localesTextDown,
      //   locales.data?._id,
      // );
      // const textUp = getLocaledValue(currentDaily.aspectQuote.localesTextUp, locales.data?._id);
      // const textDown = getLocaledValue(currentDaily.aspectQuote.localesTextDown, locales.data?._id);
      // setCurrentDailyText(`${text}`.replace(/\.\ /g, ".\n\n"));
      setCurrentDailyText(`${unufiedNewLine(text)}`);
      // console.log("currentDaily", currentDaily);

      if (!currentDaily.aspectQuote.memeImageUrl) {
        // отключаем пока эту логику
        // loadContentElement(currentDaily._id);
      }
    }
  }, [currentDaily, sessionStore.premium]);

  const resetSwiper = useCallback(() => {
    swiper && swiper.slideTo(0);
  }, [swiper]);

  const loadContent = useCallback(() => {
    setIsLoading(true);
    props.getDailyContent(
      {},
      {
        onSuccess(body) {
          setDailyContents(body);
          setIsLoading(false);
          setLastLoaded(new Date());
          setTimeout(() => {
            resetSwiper();
          }, 100);

          // setContent(body);
          // setIsLoaded(false);
        },
        onFailure() {
          setIsLoading(false);
        },
        onRequest() {
          setDailyContents([]);
          // setIsLoaded(true);
        },
      },
    );
  }, []);

  const freeLimit = 3; // лимит бесплатных прогнозов

  const updateSwiperSize = useCallback(() => {
    swiper.updateAutoHeight(200);
  }, [swiper]);

  const loadContentElement = useCallback(
    (contentId) => {
      props.getDailyContentElement(
        { query: { contentId, localeId: locales.data?._id } },
        {
          onSuccess(body) {
            // заменим содержимое в списке по факту получения id картинки
            setDailyContents(
              _.map(dailyContents, (content) => (content._id === body._id ? body : content)),
            );
            setTimeout(() => updateSwiperSize(), 500);
          },
          onRequest() {
            // setIsLoaded(true);
          },
        },
      );
    },
    [dailyContents, swiper],
  );

  const [openModal, setOpenModal] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      // loadContent(); // отключим загрузку типового контента
    }
  }, [sessionStore.account.aspectsCategoriesId]);

  return true || dailyContents.length ? (
    <div className="text-center">
      {/* 
      <div className="mb-4 large-header">{translate("horoscope_today")}</div>
      <div className="position-relative">
        <div
          className="px-1 py-0 rounded standart-header"
          style={{
            position: "absolute",
            zIndex: 10,
            top: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, .38)",
            color: "white",
            // fontSize: "0.9rem",
          }}
        >
          {`${currentIndex + 1}/${dailyContents.length + 0}`}
        </div>

        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          autoHeight={true}
          observer={true}
          observeSlideChildren={true}
          // setWrapperSize={true}
          pagination={false}
          onSwiper={setSwiper}
          onSlideChange={slideChangeHandler}
        >
          {dailyContents.map((dailyContent, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="text-center position-relative">
                  <MemeImage url={dailyContent.aspectQuote.memeImageUrl} />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="pt-2 pb-3">
          <ShowPager total={dailyContents.length + 0} current={currentIndex} swiper={swiper} />
        </div>

        {currentDaily && (
          <div>
            <div className="position-relative">
              <div className="mb-4 d-flex justify-content-center">
                <div className="my-auto">
                  {currentDaily.aspectCategory.icon} {currentDaily.aspectCategory.name}
                </div>
                <div className="mr-3"></div>

                <div className="my-auto">
                  <FriendZodiac {...sessionStore.account} withPhoto={true} />
                </div>
              </div>

              <div className="content-html position-relative">
                {currentIndex >= freeLimit && noPremium ? (
                  <div
                    className="position-absolute w-100 h-100"
                    style={{ zIndex: 99, top: 0, left: 0 }}
                  >
                    <div className="d-flex align-items-stretch h-100 w-100">
                      <PremiumBlock asLock={true} />
                    </div>
                  </div>
                ) : null}

                <div style={{ filter: currentIndex >= freeLimit && noPremium ? "blur(4px)" : "" }}>
                  <div>{nl2br(currentDailyText)}</div>

                  <div className="mt-4 d-flex justify-content-center">
                    <PlanetAspectElement {...planetAspectData} />
                  </div>
                  <div className="pt-4">
                    <WebAppShareButton share={"aspect"} contentId={currentDaily._id} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
       */}

      <PeriodicContent type={"daily_horoscope"} isTemporary={true} />

      <div className="pt-5" />
      <PeriodicContent type={"horoscope"} />

      <div className="pt-5" />
      <PeriodicContent type={"finances"} />

      <div className="pt-5" />
      <ExtraInfoBlock />
    </div>
  ) : (
    <div className="text-center p-5">
      <LoadingIcon />
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {
  getDailyContent: api.get("/account/webapp/aspects"),
  getDailyContentElement: api.get("/account/webapp/aspects/info"),
})(AspectsBlock);
