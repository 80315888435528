import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import WebApp from "@twa-dev/sdk";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { history } from "store";
import { doFriendsRefresh } from "actions";
//
import { customerApi } from "reducers/api";
import { LoadingIcon } from "components/standart";
import Col3Container from "components/standart/col3Container";
import LocalesValues from "components/standart/LocalesValues";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";
import { WebAppUserpic } from "components/standart/webapp-userpic";
import { UsersTogetherPics } from "../common";
import { ConfettiBlock } from "../../confetti";

const { api, session } = customerApi.actions;

const InviteCheck = (props) => {
  const { currentSize, sessionStore } = props;
  const { translate, systemEnv, successHandler } = props;
  const { settings } = props.env.www;

  const [loading, setLoading] = useState(false);
  const [inviteData, setInviteData] = useState(null);
  const [inviteAccepted, setInviteAccepted] = useState(false);
  const [inviteWrong, setInviteWrong] = useState(false);

  useEffect(() => {
    const [action, inviteId] = _.split(WebApp.initDataUnsafe.start_param, "-");
    if (action === "invite" && inviteId) {
      // сбросим значение, чтобы не было повторений в случае штатной перезагрузки компонент
      WebApp.initDataUnsafe.start_param = null;
      props.checkInvite(
        { body: { inviteId } },
        {
          onSuccess(body) {
            setOpenModal(true);
            setInviteData(body);
          },
          onFailure() {
            // в случае ошибки отключим модалку вовсе
            // setOpenModal(true);
            // setInviteWrong(true);
          },
        },
      );
    }
  }, []);

  const clickHandler = useCallback(() => {
    if (inviteWrong || inviteAccepted) {
      props.getUser(
        {},
        {
          onRequest() {
            setLoading(true);
          },
          onSuccess() {
            inviteAccepted && history.push("/app/friends");
            setOpenModal(false);
          },
        },
      );
    } else {
      acceptInviteHandler();
    }
  }, [inviteWrong, inviteAccepted, inviteData]);

  const acceptInviteHandler = useCallback(() => {
    const inviteId = inviteData.invite._id;
    props.acceptInvite(
      { body: { inviteId } },
      {
        onSuccess() {
          props.doFriendsRefresh();
          setInviteAccepted(true);
          setLoading(false);
        },
        onRequest() {
          setLoading(true);
        },
        onFailure() {
          setLoading(false);
        },
      },
    );
  }, [inviteData]);

  const [openModal, setOpenModal] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);

  return (
    <>
      {openModal && (
        <Modal
          centered
          wrapClassName="fullscreen-modal"
          isOpen={openModal}
          size="md"
          // toggle={toggleModal}
        >
          {/* <ModalHeader toggle={toggleModal}></ModalHeader> */}
          <ModalBody className="py-0">
            <Col3Container>
              <ConfettiBlock active={inviteAccepted} />

              {
                <div className="text-center pt-5">
                  <div className="large-header mb-3">
                    <LocalesValues
                      values={
                        inviteWrong
                          ? settings.inviteAction.actionFailureTitle
                          : inviteAccepted
                          ? settings.inviteAction.actionSuccessTitle
                          : settings.inviteAction.confirmTitleText
                      }
                      isHtml={false}
                    />
                  </div>

                  <div className="standart-header mb-4">
                    <LocalesValues
                      values={
                        inviteWrong
                          ? settings.inviteAction.actionFailureText
                          : inviteAccepted
                          ? settings.inviteAction.actionSuccessText
                          : settings.inviteAction.confirmMainText
                      }
                      isHtml={false}
                    />
                  </div>

                  {inviteData?.sender && !inviteWrong ? (
                    <div className="py-4">
                      <UsersTogetherPics
                        account1={sessionStore.account}
                        account2={inviteData.sender}
                      />
                    </div>
                  ) : null}

                  <div className="form-group text-center my-4">
                    <button
                      type="button"
                      className="btn btn-solid"
                      disabled={loading}
                      onClick={clickHandler}
                    >
                      {loading ? (
                        <LoadingIcon className="mr-2" />
                      ) : (
                        <i className="fa fa-check mr-2" />
                      )}
                      <LocalesValues
                        values={
                          inviteWrong
                            ? settings.inviteAction.actionFailureButton
                            : inviteAccepted
                            ? settings.inviteAction.actionSuccessButton
                            : settings.inviteAction.confirmButtonText
                        }
                        isHtml={false}
                      />
                    </button>
                  </div>

                  {inviteAccepted || inviteWrong ? null : (
                    <div className="form-group text-center">
                      <button
                        type="button"
                        className="btn btn-outline"
                        disabled={loading}
                        onClick={toggleModal}
                      >
                        <i className="fa fa-times mr-2" />
                        {translate("decline")}
                      </button>
                    </div>
                  )}
                </div>
              }
            </Col3Container>
            <BottomNaviSpacing />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

const mapeStateToProps = (state) => ({
  systemEnv: state.customer.env,
  env: state.frontend.env,
  sessionStore: state.customer.session,
});
export default connect(mapeStateToProps, {
  ...session.actions,
  doFriendsRefresh,
  checkInvite: api.post("/account/webapp/invites/check"),
  acceptInvite: api.post("/account/webapp/invites/accept"),
})(withTranslate(InviteCheck));
