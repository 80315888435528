import _ from "lodash";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { LoadingIcon, unufiedNewLine } from "components/standart";
import { customerApi } from "reducers/api";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import MemeImage from "components/standart/memeImage";
import nl2br from "react-nl2br";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";
import Col3Container from "components/standart/col3Container";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";
import WebAppShareButton from "components/standart/webapp-share-button";
import { temporaryDays } from "views/init";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { FriendZodiac } from "./friends/friendsDataElements";
import PremiumBlock from "./premium";
import { PlanetAspectSumary } from "./planetAspect";

dayjs.extend(utc);
dayjs.extend(timezone);

const { api, session } = customerApi.actions;
const PeriodicContent = (props) => {
  const { type, sessionStore } = props;
  const { locales } = useSelector((state) => state);
  const [content, setContent] = useState(null);
  const [isTodayContent, setTodayContent] = useState(false);
  const [isLoading, setLoading] = useState(null);
  const [availablePremium, setAvailablePremium] = useState(false);

  const translate = useTranslate();

  const loadContent = () => {
    props.getContent(
      { query: { type } },
      {
        onSuccess(content) {
          setContent(content);
          setLoading(false);
          setTodayContent(
            content.periodicText
              ? dayjs(content.periodicText?.period?.fromDate).isSame(dayjs(), "day")
              : false,
          );
        },
        onFailure() {
          setLoading(false);
        },
        onRequest() {
          setContent(null);
          setLoading(true);
        },
      },
    );
  };

  // проверка, что доступен премиум контент
  useEffect(() => {
    // если это временный доступ, то проверим, что дата регистрации аккаунта не превысила конец текущего дня
    if (props.isTemporary) {
      if (sessionStore.premium) {
        setAvailablePremium(true);
      } else {
        const accountEndDay = dayjs(sessionStore.account.createdAt)
          .add(temporaryDays, "day")
          .endOf("day")
          .toDate();
        const endOfToday = dayjs(sessionStore.today).endOf("day").toDate();
        // премиум доступен, если дата регистрации аккаунта не старше одного дня от текущей даты
        setAvailablePremium(accountEndDay > endOfToday);
      }
    } else {
      // иначе - премиум только если бесплатные настройки, или если есть сам премиум
      setAvailablePremium(props.isFree || !!sessionStore.premium);
    }
  }, [
    props.isTemporary,
    props.isFree,
    sessionStore.premium,
    sessionStore.account,
    sessionStore.today,
  ]);

  const [previewText, setPreviewText] = useState(null);
  const [largeText, setLargeText] = useState(null);

  useEffect(() => {
    if (content && content.periodicText) {
      const text = getLocaledValue(content.periodicText.localesText, locales.data._id);
      const [text1, ...anotherText] = _.split(text, "\n").map(_.trim).filter(_.size);
      setLargeText(`${_.trim(unufiedNewLine(text))}`);
      setPreviewText(`${_.trim(unufiedNewLine([text1].join("\n")))}`);
      // setLargeText(`${text}`.replace(/\. /g, ".\n\n"));
      // setLargeText(`${_.trim(unufiedNewLine(text))}`);
      // setPreviewText(`${_.trim(_.first(_.split(text, "\n")))}`);
    }
  }, [content]);

  const [openModal, setOpenModal] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);

  // при смене знака зодиака перезагружаем контент
  useEffect(() => {
    loadContent();
  }, [sessionStore.account.astroSign]);

  const showDateValue = (date) => {
    const value = dayjs(date).utc().tz("Europe/Moscow").format("DD.MM");
    return value;
    // const [day, month] = dayjs(date).format("D-M").split("-");
    // return `${day} ${translate(`month_rod_${month}`)}`;
  };

  const showDate = (period) => {
    if (period) {
      const date1 = showDateValue(period.fromDate);
      const date2 = showDateValue(period.toDate);
      return date1 !== date2 ? `🗓 ${date1} - ${date2}` : `🗓 ${date1}`;
    }
    return null;
  };

  const planetsAspects = useMemo(
    () =>
      content && content.periodicText ? (
        <PlanetAspectSumary
          periodId={content.periodicText.periodId}
          astroSign={sessionStore.account.astroSign}
        />
      ) : null,
    [content, sessionStore],
  );

  return (
    <div>
      {isLoading ? (
        <div className="text-center py-5">
          <LoadingIcon />
        </div>
      ) : null}

      {content && content.periodicText ? (
        <div className="text-center">
          <div className="large-header">{translate(`periodic_content_${type}`)}</div>

          <div className="pt-4">
            <div className="position-relative">
              <MemeImage url={content.periodicText.memeImageUrl} />
              {!props.isTemporary && isTodayContent ? (
                <div
                  className="px-1 py-0 rounded standart-header"
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    top: 0,
                    right: 0,
                    backgroundColor: "#fd3b2f",
                    color: "white",
                    fontSize: "0.9rem",
                  }}
                >
                  {translate("new")}
                </div>
              ) : null}
            </div>
          </div>

          <div className="mt-4 d-flex justify-content-center">
            <div className="my-auto">{showDate(content.periodicText.period)}</div>
            <div className="mx-1"></div>
            <div className="my-auto">
              <FriendZodiac {...sessionStore.account} withPhoto={true} />
            </div>
          </div>

          <div className="pt-4">
            {nl2br(previewText)}
            {/* <LocalesValues values={content.periodicText.localesPreviewText} /> */}
          </div>
          {/* 
          <div className="pt-4" />
          {planetsAspects}
           */}
          <div className="pt-4" />

          {
            /* currentSize < settings.freeFriends || */
            availablePremium ? (
              <div>
                <div className="">
                  <div>
                    <button type="button" className="btn btn-solid" onClick={toggleModal}>
                      <i className="fa fa-eye mr-2" />
                      {translate("read_more")}
                    </button>
                  </div>
                  {openModal && (
                    <Modal
                      centered
                      wrapClassName="fullscreen-modal"
                      isOpen={openModal}
                      size="md"
                      // toggle={toggleModal}
                    >
                      <ModalHeader toggle={toggleModal}></ModalHeader>
                      <ModalBody>
                        <Col3Container>
                          {/* проверим, что текущее количество друзей входит в лимит, или у пользователя есть премиум */}
                          {
                            <div className="text-center">
                              <div className="large-header">
                                {translate(`periodic_content_${type}`)}
                              </div>
                              <div className="pt-4" />

                              <div>
                                <MemeImage url={content.periodicText.memeImageUrl} />
                              </div>

                              <div className="mt-4 d-flex justify-content-center">
                                <div className="my-auto">
                                  {showDate(content.periodicText.period)}
                                </div>
                                <div className="mr-3"></div>
                                <div className="my-auto">
                                  <FriendZodiac {...sessionStore.account} withPhoto={true} />
                                </div>
                              </div>

                              <div className="pt-4" />

                              <div>{nl2br(largeText)}</div>

                              <div className="pt-4" />
                              {planetsAspects}

                              <div className="pt-4" />
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-solid"
                                  onClick={toggleModal}
                                >
                                  <i className="fa fa-chevron-left mr-2" />
                                  {translate("back")}
                                </button>
                              </div>

                              {!sessionStore.premium ? (
                                <div>
                                  <div className="pt-4" />
                                  <PremiumBlock asTemporaryText={true} />{" "}
                                </div>
                              ) : null}

                              <div className="py-5" />
                              <div className="py-5" />
                              <div className="py-5" />
                            </div>
                          }
                        </Col3Container>
                        <BottomNaviSpacing />
                      </ModalBody>
                    </Modal>
                  )}
                </div>
              </div>
            ) : (
              <div className="position-relative">
                <PremiumBlock buttonIcon={"fa-lock"} buttonText={translate("read_more_premium")} />
              </div>
            )
          }

          <div className="pt-4" />
          <WebAppShareButton share={"periodQuote"} contentId={content.periodicText._id} />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {
  getContent: api.get("/account/webapp/periodic"),
})(PeriodicContent);
