import _ from "lodash";
import React, { useState, useEffect, useRef, useCallback } from "react";

import { connect, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { LoadingIcon, unufiedNewLine } from "components/standart";
import { customerApi } from "reducers/api";
import MemeImage from "components/standart/memeImage";
import WebAppShareButton from "components/standart/webapp-share-button";
import Col3Container from "components/standart/col3Container";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";

import { FriendsNamesInfo } from "./friendsDataElements";
import PremiumBlock from "../premium";

import nl2br from "react-nl2br";
import { PlanetAspectSumary } from "../planetAspect";

const { api, session } = customerApi.actions;

const ShowFriendsModal = (props) => {
  const translate = useTranslate();
  // const location = useLocation();
  const { locales } = useSelector((state) => state);

  const { sessionStore, currentFriend, setCurrentFriend } = props;

  const [largeText, setLargeText] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setOpenModal(!!currentFriend);
  }, [currentFriend]);

  const loadData = useCallback(() => {
    const text = getLocaledValue(currentFriend.periodQuoteText.localesText, locales.data?._id);
    setLargeText(`${_.trim(unufiedNewLine(text))}`);

    // const [text1, ...anotherText] = _.split(text, "\n").map(_.trim).filter(_.size);
    // setLargeText(`${_.trim(unufiedNewLine(anotherText.join("\n")))}`);
  }, [currentFriend]);

  useEffect(() => {
    if (!openModal) {
      setCurrentFriend(null);
    } else {
      loadData();
    }
  }, [openModal]);

  return (
    <div>
      {openModal && (
        <Modal
          centered
          wrapClassName="fullscreen-modal"
          isOpen={openModal}
          size="lg"
          // toggle={toggleModal}
        >
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <Col3Container>
              <div className="text-center">
                {isLoading ? <LoadingIcon /> : null}
                {currentFriend.periodQuoteTextId ? (
                  <div>
                    <div className="large-header">{translate("friends_today")}</div>

                    <div className="pt-4" />
                    <MemeImage url={currentFriend.periodQuoteText.memeImageUrl} />
                    <div className="pt-4" />
                    <div>
                      <FriendsNamesInfo
                        customer={sessionStore.account}
                        friend={currentFriend.anotherCustomer || currentFriend}
                      />
                    </div>

                    <div className="pt-4" />

                    <div>{nl2br(largeText)}</div>

                    <div className="pt-4" />
                    <PlanetAspectSumary
                      periodId={currentFriend.periodQuoteText.periodId}
                      astroSign={
                        currentFriend.anotherCustomer
                          ? currentFriend.anotherCustomer.astroSign
                          : currentFriend.astroSign
                      }
                    />

                    {/* 
                    <div className="pt-4" />
                    <WebAppShareButton share={"compatibility"} contentId={currentFriend._id} />
                     */}

                    <div className="pt-4" />
                    <div className="form-group text-center">
                      <button type="button" className="btn btn-solid" onClick={toggleModal}>
                        <i className="fa fa-chevron-left mr-2" />
                        {translate("back")}
                      </button>
                    </div>

                    {!sessionStore.premium ? (
                      <div>
                        <div className="pt-4" />
                        <PremiumBlock asTemporaryText={true} />{" "}
                      </div>
                    ) : null}

                    <div className="py-5" />
                    <div className="py-5" />
                    <div className="py-5" />
                  </div>
                ) : null}
              </div>
            </Col3Container>
            <BottomNaviSpacing />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {
  ...session.actions,
  getFriendInfo: api.get("/account/webapp/friends/info"),
})(ShowFriendsModal);
