import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import BirthDateCalendar from "components/standart/BirthDateCalendar";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { customerApi } from "reducers/api";
import { LoadingIcon } from "components/standart";
import GiphyImage from "components/standart/giphyImage";
import Col3Container from "components/standart/col3Container";
import LocalesValues from "components/standart/LocalesValues";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";
import WebApp from "@twa-dev/sdk";
import MemeImage from "components/standart/memeImage";

import { ConfettiBlock } from "../../confetti";
const { api, session } = customerApi.actions;

const GiftsModal = (props) => {
  const { locales, currentSize, sessionStore } = props;
  const { translate, systemEnv, successHandler } = props;
  const { settings } = props.env.www;
  const { premiums, currencies } = systemEnv;
  const [currenciesById, setCurrenciesId] = useState({});
  const [currentPremium, setCurrentPremium] = useState(null);
  const [currentGift, setCurrentGift] = useState(null);

  const [loading, setLoading] = useState(false);
  const [gifts, setGifts] = useState([]);
  const [giftPaymentSuccess, setGiftPaymentSuccess] = useState(false);

  useEffect(() => {
    setCurrenciesId(_.keyBy(currencies, "_id"));
  }, [currencies]);

  const sendGiftHandler = useCallback(
    (giftId) => {
      //
      setCurrentGift(giftId);
      props.prepareMessage(
        { body: { giftId, localeId: locales.data._id } },
        {
          onFailure() {
            setCurrentGift(null);
          },
          onSuccess({ id, giftId }) {
            //
            setCurrentGift(null);
            try {
              WebApp.shareMessage(id, (isSent) => {
                if (isSent) {
                  props.sendMessage(
                    { body: { giftId } },
                    {
                      onSuccess() {
                        loadGifts();
                      },
                    },
                  );
                }
              });
            } catch (e) {
              console.error(e);
            }
          },
        },
      );
    },
    [locales],
  );

  const requestGiftHandler = (premium, price, currency) => () => {
    const body = { premiumId: premium._id, currencyId: currency._id, localeId: locales.data._id };

    props.requestGift(
      { body },
      {
        onSuccess({ valueId, url }) {
          setLoading(false);
          setCurrentPremium(null);
          WebApp.openInvoice(url, (status) => {
            if (status === "paid") {
              loadGifts();
              setGiftPaymentSuccess(true);
              sendGiftHandler(valueId);
            }
          });
        },
        onRequest() {
          setCurrentPremium(premium._id);
          setLoading(true);
        },
        onFailure() {
          setCurrentPremium(null);
          setLoading(false);
        },
      },
    );
  };

  const loadGifts = useCallback(() => {
    props.getGifts(
      {},
      {
        onRequest() {},
        onSuccess(body) {
          setGifts(body);
        },
      },
    );
  }, []);

  const [openModal, setOpenModal] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    if (openModal) {
      loadGifts();
    } else {
      setGifts([]);
    }
  }, [openModal]);

  return (
    <div>
      <div className="form-group">
        <div className="text-center">
          <button type="button" className="btn btn-solid" onClick={toggleModal}>
            <i className="fa fa-gift mr-2" />

            <LocalesValues
              values={settings.giftScreenButton}
              isHtml={false}
              defaultValue={translate("gifts")}
            />
          </button>
        </div>
        {openModal && (
          <Modal
            centered
            wrapClassName="fullscreen-modal"
            isOpen={openModal}
            size="md"
            // toggle={toggleModal}
          >
            <ModalHeader toggle={toggleModal}></ModalHeader>
            <ModalBody>
              <Col3Container>
                <ConfettiBlock active={giftPaymentSuccess} />
                <div className="text-center">
                  {/* проверим, что текущее количество друзей входит в лимит, или у пользователя есть премиум */}
                  <div className="large-header mb-4">
                    <LocalesValues values={settings.giftScreenTitle} isHtml={false} />
                  </div>

                  <div className="standart-header mb-4">
                    <LocalesValues values={settings.giftScreenText} isHtml={false} />
                  </div>

                  {_.map(premiums, (premium) => {
                    return (
                      <div key={premium._id} className="flex-fill mb-4">
                        {_.map(premium.prices, (price) => {
                          const currency = currenciesById[price.currencyId];
                          return currency ? (
                            <button
                              key={`${premium._id}-${currency._id}`}
                              className="btn btn-solid"
                              disabled={loading}
                              // onClick={startPaymentHandler(premium._id, currency._id)}
                              onClick={requestGiftHandler(premium, price, currency)}
                            >
                              {loading && currentPremium === premium._id ? (
                                <LoadingIcon className="mr-2" />
                              ) : (
                                <i className="fa fa-gift mr-2" />
                              )}
                              <LocalesValues
                                values={premium.localesGiftButton}
                                defaultValue={settings.giftAction.actionButtonText}
                                isHtml={false}
                              />
                            </button>
                          ) : null;
                        })}
                      </div>
                    );
                  })}

                  <div className="standart-header content-html">
                    <LocalesValues values={settings.premiumHelpText} isHtml={true} />
                  </div>
                </div>

                {gifts.length ? (
                  <div className="pt-4">
                    <div className="text-center large-header pt-2 mb-4">
                      <LocalesValues values={settings.giftStoryTitle} isHtml={false} />
                    </div>
                    {_.map(gifts, (gift) => {
                      const allowToSent = !gift.sentAt && !currentGift;
                      const color = gift.acceptedAt
                        ? "-text-default"
                        : gift.sentAt
                        ? "-text-default"
                        : "text-danger";
                      const text = gift.acceptedAt
                        ? translate("gift_accepted")
                        : gift.sentAt
                        ? translate("gift_sent")
                        : translate("gift_new");
                      const [day, month] = dayjs(gift.updatedAt).format("D-M").split("-");
                      const date = `${day} ${translate(`month_rod_${month}`)}`;
                      return (
                        <div
                          key={gift._id}
                          className="d-flex mb-3"
                          style={allowToSent ? { cursor: "pointer" } : {}}
                          onClick={allowToSent ? () => sendGiftHandler(gift._id) : null}
                        >
                          <div className="col-3 my-auto text-center">
                            {currentGift === gift._id ? (
                              <LoadingIcon />
                            ) : (
                              <>
                                {gift.imageData ? (
                                  <GiphyImage
                                    {...gift.imageData}
                                    // className="rounded-circle"
                                    style={{ height: "50px" }}
                                  />
                                ) : null}
                                {gift.memeImageUrl ? (
                                  <MemeImage
                                    url={gift.memeImageUrl}
                                    style={{ maxHeight: "50px" }}
                                  />
                                ) : null}
                              </>
                            )}
                          </div>
                          <div className={`col ${color}`}>
                            <div>{text}</div>
                            <div className="hashTag">
                              {date} (<LocalesValues values={gift.premium.localesName} />)
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="standart-header content-html text-center">
                      <LocalesValues values={settings.giftHelpText} isHtml={true} />
                    </div>
                  </div>
                ) : null}
              </Col3Container>
              <BottomNaviSpacing />
            </ModalBody>
          </Modal>
        )}
      </div>
    </div>
  );
};

const mapeStateToProps = (state) => ({
  systemEnv: state.customer.env,
  env: state.frontend.env,
  sessionStore: state.customer.session,
  locales: state.locales,
});

export default connect(mapeStateToProps, {
  ...session.actions,
  getGifts: api.get("/account/webapp/gifts"),
  requestGift: api.post("/account/webapp/gifts/request"),
  prepareMessage: api.post("/account/webapp/gifts/message"),
  sendMessage: api.post("/account/webapp/gifts/send"),
})(withTranslate(GiftsModal));
