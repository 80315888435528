import _ from "lodash";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { useSelector } from "react-redux";
import WebAppUserpic from "components/standart/webapp-userpic";
import { useDispatch } from "react-redux";

import nl2br from "react-nl2br";
import { useTranslate } from "react-redux-multilingual";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { customerApi } from "reducers/api";
import { LoadingIcon, unufiedNewLine } from "components/standart";
import MemeImage from "components/standart/memeImage";
import WebAppShareButton from "components/standart/webapp-share-button";

const { api } = customerApi.actions;

export const PlanetAspectElement = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const translate = useTranslate();

  const toggleModal = () => setOpenModal(!openModal);

  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={toggleModal}>
        <PlanetAspectInfo {...props} />
      </div>
      {openModal ? (
        <Modal
          size="lg"
          // toggle={toggleModal}
          centered={true}
          wrapClassName="fullscreen-modal"
          isOpen={true}
        >
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <PlanetAspectContent {...props} />
            <div className="pt-4" />
            <div className="form-group text-center">
              <button type="button" className="btn btn-solid" onClick={toggleModal}>
                <i className="fa fa-chevron-left mr-2" />
                {translate("back")}
              </button>
            </div>
            <div className="py-5" />
            <div className="py-5" />
            <div className="py-5" />
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

export const PlanetAspectSimpleElement = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const translate = useTranslate();

  const toggleModal = () => setOpenModal(!openModal);
  const { planet } = props;

  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={toggleModal}>
        {translate(`planets_${planet}`)}
      </span>
      {openModal ? (
        <Modal
          size="lg"
          // toggle={toggleModal}
          centered={true}
          wrapClassName="fullscreen-modal"
          isOpen={true}
        >
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <PlanetAspectContent {...props} />
            <div className="pt-4" />
            <div className="form-group text-center">
              <button type="button" className="btn btn-solid" onClick={toggleModal}>
                <i className="fa fa-chevron-left mr-2" />
                {translate("back")}
              </button>
            </div>
            <div className="py-5" />
            <div className="py-5" />
            <div className="py-5" />
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

export const PlanetCategoryAspectSimpleElement = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const translate = useTranslate();

  const toggleModal = () => setOpenModal(!openModal);
  const { planet, category } = props;

  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={toggleModal}>
        {<LocalesValues values={category.localesName} defaultValue={category.name} />}
      </span>
      {openModal ? (
        <Modal
          size="lg"
          // toggle={toggleModal}
          centered={true}
          wrapClassName="fullscreen-modal"
          isOpen={true}
        >
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <PlanetAspectContent {...props} />
            <div className="pt-4" />
            <div className="form-group text-center">
              <button type="button" className="btn btn-solid" onClick={toggleModal}>
                <i className="fa fa-chevron-left mr-2" />
                {translate("back")}
              </button>
            </div>
            <div className="py-5" />
            <div className="py-5" />
            <div className="py-5" />
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

export const PlanetAspectInfo = (props) => {
  const translate = useTranslate();
  const [aspectText, setAspectText] = useState(null);
  const [aspectPlanet, setAspectPlanet] = useState(null);
  const { locales } = useSelector((state) => state);

  useEffect(() => {
    const { planet, aspectValue } = props;
    if (planet && aspectValue) {
      const { icon } = aspectValue;
      const aspect = getLocaledValue(aspectValue.localesName, locales.data?._id);
      setAspectText(translate("aspect_text", { icon, aspect }));
      setAspectPlanet(translate(`planets_${planet}`));
    }
  }, [props, locales]);

  return (
    <div className="d-flex justify-content-center">
      <div className="my-auto">{aspectText}</div>
      <div className="my-auto mx-1">{translate("accused_by")}</div>
      <div className="my-auto -hashTag">
        <u>{aspectPlanet}</u>
      </div>
    </div>
  );
};

const getPlanetAspectInfo = api.get("/account/webapp/quotes-memes/planetAspect");

export const PlanetAspectContent = (props) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(null);
  const { locales } = useSelector((state) => state);

  const { planet, aspectValue } = props;

  useEffect(() => {
    dispatch(
      getPlanetAspectInfo(
        { query: { planet, aspectValueId: aspectValue._id } },
        {
          onRequest() {
            setInfo({});
            setLoading(true);
          },
          onFailure() {
            setLoading(false);
          },
          onSuccess(body) {
            setInfo(body);
            setLoading(false);
          },
        },
      ),
    );
  }, []);

  return (
    <div className="text-center">
      {loading ? <LoadingIcon /> : null}
      {info._id ? (
        <div>
          <div className="large-header">
            {translate(`planets_${planet}`)}{" "}
            {getLocaledValue(aspectValue.localesText, locales.data?._id)}
          </div>

          <div className="pt-4" />
          <MemeImage url={info.memeImageUrl} />

          {/* 
          <div className="pt-4" />
          <div className="d-flex justify-content-center">
            <PlanetAspectInfo {...props} />
          </div>
          */}

          <div className="pt-4" />
          {nl2br(unufiedNewLine(getLocaledValue(info.localesText, locales.data?._id)))}
          <div className="pt-4" />
          <WebAppShareButton share={"aspectPlanet"} contentId={info._id} />
        </div>
      ) : null}
    </div>
  );
};

const getPlanetAspectSumary = api.get("/account/webapp/periodic/planetsSummary");

export const PlanetAspectSumary = (props) => {
  const dispatch = useDispatch();
  const { periodId, astroSign } = props;
  const translate = useTranslate();
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(null);
  const { locales } = useSelector((state) => state);

  useEffect(() => {
    dispatch(
      getPlanetAspectSumary(
        { query: { periodId, astroSign } },
        {
          onRequest() {
            setInfo({});
            setLoading(true);
          },
          onFailure() {
            setLoading(false);
          },
          onSuccess(body) {
            setInfo(body);
            setLoading(false);
          },
        },
      ),
    );
  }, [props.periodId, props.astroSign]);

  return (
    <div className="text-center">
      {loading ? <LoadingIcon /> : null}
      {info.aspectsValues ? (
        <div className="-text-left">
          {_.map(info.aspectsValues, (aspectValue) => {
            const aspectValueId = aspectValue._id;
            const [aspectPlanets] = info.aspectsPlanets.filter(
              (value) => value.aspectValueId === aspectValueId,
            );
            const categoriesKeys = _.keyBy(info.categories, "planet");
            return aspectPlanets ? (
              <div key={aspectValueId}>
                <div className="mb-2">
                  <span className="mr-1 text-nowrap">
                    {aspectValue.icon} {getLocaledValue(aspectValue.localesName, locales.data?._id)}
                  </span>
                  <span>
                    {_.map(aspectPlanets.planets, (planet, index) => {
                      const notIsLast = index + 1 < aspectPlanets.planets.length;
                      const category = categoriesKeys[planet];
                      return (
                        <Fragment key={`${aspectValueId}-${planet}`}>
                          <span className="mr-1 text-nowrap">
                            <u>
                              <PlanetAspectSimpleElement
                                {...{ planet, category, aspectValue }}
                              />
                            </u>
                            {notIsLast ? "," : null}
                          </span>{" "}
                        </Fragment>
                      );
                    })}
                  </span>
                </div>
              </div>
            ) : null;
          })}
        </div>
      ) : null}
    </div>
  );
};
