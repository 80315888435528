import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";
// import Sitemap from "./sitemap";

import Header from "components/common/headers/header";
import Footer from "components/common/footers/footer";

import Layout from "./views/layout";

import Index from "./views";
import IndexWebApp from "./views/index.webapp";
import Account from "./views/account";
import SharedContent from "./views/share";
import GoPage from "./views/go";
import Demo from "./views/demo";
import WebApp from "./views/webapp";

import SettingsButton from "./views/account/settings/button";

// import Partners from "./views/partners";
// import PartnersLanding from "./views/partners/landing";

import Content from "./views/content";
import Feedback from "./views/feedback";

// import Feedback from "./views/feedback";

const RouteElem = (props) => {
  return (
    <Layout>
      <Header webApp={props.webApp} />
      {props.children}
      <Footer webApp={props.webApp} />
    </Layout>
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path={`/`}
        component={() => (
          <RouteElem>
            <IndexWebApp />
          </RouteElem>
        )}
      />
      <Route
        exact
        path={`/account`}
        component={() => (
          <RouteElem>
            <Redirect to={"/"} />
            {/* <Account /> */}
          </RouteElem>
        )}
      />
      <Route
        exact
        path={`/demo`}
        component={() => (
          <RouteElem>
            <Redirect to={"/"} />
            {/* <Demo /> */}
          </RouteElem>
        )}
      />
      <Route
        exact
        path="/offer"
        component={() => (
          <RouteElem>
            <Redirect to={"/"} />
            {/* <Content /> */}
          </RouteElem>
        )}
      />
      <Route
        exact
        path="/share/:segment/:id"
        component={() => (
          <RouteElem>
            <Redirect to={"/"} />
            {/* <SharedContent /> */}
          </RouteElem>
        )}
      />
      <Route
        exact
        path="/go"
        component={() => (
          <RouteElem>
            <Redirect to={"/"} />
            {/* <GoPage /> */}
          </RouteElem>
        )}
      />
      <Route
        exact
        path="/feedback"
        component={() => (
          <RouteElem>
            <Redirect to={"/"} />
            {/* <Feedback /> */}
          </RouteElem>
        )}
      />
      <Route
        // exact
        path="/app"
        component={() => (
          <RouteElem webApp={true}>
            <WebApp />
          </RouteElem>
        )}
      />
    </Switch>
  );
};

export default Routes;
