import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import { metrikaTargetClick } from "services";
import { scrollTop, LogoImage } from "components/standart";
import { Link } from "react-router-dom";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";
import giphyLogo from "assets/images/powered-giphy.png";
import { ContactsBlock } from "components/standart/ContactsBlock";

const Footer = (props) => {
  const { translate, navi } = props;

  const { settings = {} } = props.env.www || {};

  return (
    <footer>
      <section className="footer-section">
        <div className="container">
          <div className="standart-header text-center text-white py-4">
            <div className="mb-2">
              <div className="mx-auto my-auto d-flex justify-content-center">
                {settings.logoImageId ? (
                  <div className="text-right my-auto mr-2 pt-0">
                    <Link to={props.webApp ? "/app" : "/"}>
                      <LogoImage />
                    </Link>
                  </div>
                ) : null}
                <div className="text-left my-auto">
                  <div style={{ fontSize: "20px", fontFamily: "Arial Black", fontWeight: "bold" }}>
                    <Link
                      to={props.webApp ? "/app" : "/"}
                      onClick={scrollTop}
                      className="text-white text-decoration-none"
                    >
                      {"astromeme"}
                    </Link>
                  </div>

                  <div
                    style={{ fontSize: "12px", fontFamily: "Arial", marginTop: "-4px" }}
                    className="text-lowercase"
                  >
                    {translate("sitename_helper")}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="my-4" />
              <div className="pt-2" />
              <ContactsBlock color={"white"} />
              {/* 
              {settings.telegramChannelUrl && (
                <a
                  href={settings.telegramChannelUrl}
                  className="text-white large-header mx-4"
                  target="_blank"
                  data-id="vk"
                  onClick={metrikaTargetClick}
                >
                  <i className="fab fa-telegram-plane" />
                </a>
              )}

              {settings.vk && (
                <a
                  href={settings.vk}
                  className="text-white large-header mx-4"
                  target="_blank"
                  data-id="vk"
                  onClick={metrikaTargetClick}
                >
                  <i className="fab fa-vk" />
                </a>
              )}
                 */}
            </div>
          </div>
        </div>
        <BottomNaviSpacing />
        {/* <div className="d-block d-lg-none mobile-menu-space">&nbsp;</div> */}
      </section>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  navi: state.navi,
});

export default connect(mapStateToProps, null)(withTranslate(Footer));
