import { combineReducers } from "redux";
import { IntlReducer as Intl } from "react-redux-multilingual";
import { routerReducer } from "react-router-redux";
import { withReduxStateSync } from "redux-state-sync";
// Import custom components
import whatsapp from "./whatsapp";
import navi from "./navigation";
import locales from "./locales";
import indicators from "./indicators";
import { frontendApiReducer as frontend } from "./api/frontend";
import { customerApiReducer as customer } from "./api/customer";

const rootReducer = combineReducers({
  whatsapp,
  navi,
  locales,
  routerReducer,
  frontend,
  customer,
  indicators,
  Intl,
});

export default withReduxStateSync(rootReducer);

export const middlewares = [];
