import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { LoadingIcon } from "./index";

const MemeImage = (imageData = {}) => {
  const { url, blured, className, style } = imageData;
  const [isLoaded, setLoaded] = useState(false);
  const [visibility, setVisibility] = useState("invisible");

  useEffect(() => {
    setVisibility(isLoaded ? "visibile" : "invisible");
  }, [isLoaded]);

  return url ? (
    <>
      {isLoaded ? null : (
        <div className="center">
          <LoadingIcon />
        </div>
      )}
      <img
        src={url}
        className={`meme-image  mx-auto rounded-xl ${className} ${visibility}`}
        style={{
          filter: blured ? "blur(21px)" : "",
          ...style,
        }}
        onLoad={(evt) => {
          setLoaded(true);
          if (imageData.loadHandler) imageData.loadHandler(evt);
        }}
      />
    </>
  ) : null;
  // return url ? (
  //   <div
  //     className={`meme-image ${className}`}
  //     style={{
  //       backgroundImage: `url("${url}")`,
  //       filter: blured ? "blur(21px)" : "",
  //       ...style,
  //     }}
  //   ></div>
  // ) : null;
};

export default connect(null, {})(MemeImage);
