import React, { useState } from "react";

import { Route, Switch, Link, useLocation } from "react-router-dom";
// import Sitemap from "./sitemap";

import { WebAppLinks } from "./links";
import BottomMenu from "./bottom_menu";
import LockScreenModal from "./lock-screen";
import { InviteCheck } from "./data/invites";
import { GiftCheck } from "./data/gifts";
// import Feedback from "./views/feedback";

const WebAppComponent = (props) => {
  return <div style={{ overflowX: "hidden" }}>{props.children}</div>;
};


const { NODE_ENV } = process.env;

const WebAppContent = (props) => {
  const location = useLocation();
  const [timeoutComplete, setTimeoutComplete] = useState(false);
  const totalTimeout = NODE_ENV === "production" ? 7500 : 750;
  return !timeoutComplete ? (
    <LockScreenModal {...{ setTimeoutComplete, totalTimeout }} />
  ) : (
    <div>
      <InviteCheck />
      <GiftCheck />

      <Switch>
        {WebAppLinks.map((value, index) => {
          return (
            <Route
              exact
              path={value.link}
              key={index}
              render={() => (
                <WebAppComponent current={value.link}>{<value.component />}</WebAppComponent>
              )}
            />
          );
        })}
      </Switch>
    </div>
  );
};

export default WebAppContent;
