import _ from "lodash";
import { SET_INDICATOR_FRIENDS_OPENED } from "constants/ActionTypes";

export default (state = {}, action) => {
  _.defaults(state, { friends: false });
  switch (action.type) {
    // признак открыто для друзей
    case SET_INDICATOR_FRIENDS_OPENED:
      return {
        ...state,
        friends: true,
      };

    default:
  }
  return state;
};
