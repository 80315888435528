import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LocalesValues from "components/standart/LocalesValues";
import { useTranslate } from "react-redux-multilingual";
import ReactPlayer from "react-player";
import { fileUrl } from "reducers/api";

export const ExtraInfoContent = (props) => {
  const { enabled, title, text, button, url, videoFileId, fileId } = props;
  const { icon, buttonClass } = props;
  const translate = useTranslate();
  return enabled ? (
    <div className="mb-4">
      <div className="large-header">
        <LocalesValues values={title} />
      </div>
      <div className="pt-3" />
      <div className="content-html">
        <LocalesValues values={text} isHtml={false} />
      </div>
      <div className="pt-4" />

      {fileId ? (
        <div className=" mb-4">
          <img src={fileUrl(fileId)} style={{ maxWidth: "100%" }} className="rounded-xl" />
        </div>
      ) : null}

      {videoFileId ? (
        <div className="react-player mb-4">
          <ReactPlayer
            url={fileUrl(videoFileId, "stream")}
            width="100%"
            controls={true}
            height="100%"
          />
        </div>
      ) : null}

      <div>
        <a href={url} target="_blank" className={`btn btn-solid ${buttonClass}`}>
          <i className={`${icon} mr-2`} />
          <LocalesValues values={button} />
        </a>
      </div>
    </div>
  ) : null;
};

const ExtraInfoBlock = (props) => {
  const { settings } = props.env.www;

  return (
    <div>
      <ExtraInfoContent
        {...settings.telegramInfo}
        icon="fab fa-telegram-plane"
        buttonClass="telegram-btn"
      />
      <div className="pt-4" />
      <ExtraInfoContent {...settings.aboutInfo} icon="fa fa-rocket" />
      <div className="pt-4" />
    </div>
  );
};
const mapeStateToProps = (state) => ({
  env: state.frontend.env,
});
export default connect(mapeStateToProps, {})(ExtraInfoBlock);
