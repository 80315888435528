import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { history } from "store";
import { Link } from "react-router-dom";
import { useTranslate } from "react-redux-multilingual";
import ReactHtmlParser from "react-html-parser";
import { scrollTop, AvgScoreStars } from "components/standart";
import { setNavi } from "actions";
import { metrikaTargetClick } from "services";
import Faq from "./faq";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";
import Typewriter from "typewriter-effect";
import { fileUrl } from "reducers/api";

import ReactPlayer from "react-player";
import CategoriesSection, { itemsAnchorName } from "./categories";
import { FastFeedback } from "./feedback";
import Col3Container from "components/standart/col3Container";

const { GOOGLE_CERTIFICATE_NUMBER } = process.env;

const defaultLandings = {};

const IndexWebApp = (props) => {
  const translate = useTranslate();
  const { env } = useSelector((state) => state.frontend);

  const { meta = {}, settings = {} } = env.www;

  useEffect(() => {
    document.body.classList.add("theme-dark");
    scrollTop();
    props.setNavi("/");

    const startInstagramVisual = () => {
      try {
        window.instgrm.Embeds.process();
      } catch (e) {
        setTimeout(startInstagramVisual, 500);
      }
    };
    setTimeout(startInstagramVisual, 500);
  }, []);
  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
    fileId,
  } = meta.index || {};
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:keywords" content={keywords} />
        <meta property="og:image" content={fileUrl(fileId || settings.logoImageId)} />
        <meta property="image" content={fileUrl(fileId || settings.logoImageId)} />

        {GOOGLE_CERTIFICATE_NUMBER ? (
          <meta name="google-site-verification" content={GOOGLE_CERTIFICATE_NUMBER} />
        ) : null}
      </Helmet>

      <IntroSection />
      {/* <FastFeedback /> */}
      {/* <MissionSection /> */}
      {/* <ScoreSection /> */}
      <InfoSection />
      {/* <HowToSection type="b2c" /> */}
      {/* <HowToFaqSection /> */}

      <CommonBlockSection>
        <>
          <TechnologiesSection marginTop="3" blockName="technologies" />
          <TechnologiesSection marginBottom="0" blockName="technologies2" />
          <TechnologiesSection marginBottom="3" blockName="technologies3" />
          <ButtonSection />
          <ShopSection />
          {/* <CategoriesSection className="my-5" /> */}
        </>
      </CommonBlockSection>

      {/* <FastFeedback /> */}
    </>
  );
};

export const PageButtons = (props) => {
  const translate = useTranslate();
  const { env } = useSelector((state) => state.frontend);
  const { landings = {}, settings = {} } = env.www;
  return (
    <div className={`text-center ${props.className}`}>
      <a
        className="btn btn-solid telegram-btn"
        data-id="open-webapp-index"
        href={settings.telegramAppUrl}
      >
        <i className="fab fa-telegram-plane mr-2" />
        {translate("open_telegram")}
      </a>
      <div className="py-3" />
      {/* 
      <button
        className="btn btn-outline"
        data-id="login-index"
        onClick={() => history.push("/demo")}
      >
        {translate("demo")}
      </button>
       */}

      {/* 
      <button
        className="btn btn-outline"
        data-id="login-index"
        onClick={() => history.push("/account")}
      >
        {translate("enter_account")}
      </button>
       */}
    </div>
  );
};

export const CommonBlockSection = (props) => {
  const { data } = useSelector((state) => state.locales);
  const imagePath = `/screenshots/${data.code}`;
  return (
    <>
      <BlockSection {...props} className="-mt-5" blockName="block1">
        {props.block1Content}
      </BlockSection>
      <BlockSection {...props} blockName="block2">
        {props.block2Content}
      </BlockSection>
      <BlockSection {...props} className="mb-5" blockName="block3">
        {props.block3Content}
      </BlockSection>
      {props.children}
      <FaqSection />
    </>
  );
};

export default connect(null, {
  setNavi,
})(IndexWebApp);

/*
export const HowToSection = (props) => {
  const { env } = useSelector((state) => state.frontend);
  const { landings = defaultLandings, settings = {} } = env.www;
  const { title = "", content = "" } = landings[props.type]?.howto || {};
  return title || content ? (
    <section className="bg-white">
      <a name="howto" />
      <div className="-container py-2">
        <div className="row">
          <div className="col-lg-3 col-sm-hidden" />
          <div className="col">
            <div className="text-center">
              <div className="large-header">{ReactHtmlParser(title)}</div>
            </div>
            {settings.introVideo ? (
              <div className="row mt-3">
                <div className="col-12 col-lg-10 mx-auto">
                  <div dangerouslySetInnerHTML={{ __html: settings.introVideo }} />
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-lg-3 col-sm-hidden" />
        </div>
      </div>
    </section>
  ) : null;
};
*/

export const TechnologiesSection = (props) => {
  const { env } = useSelector((state) => state.frontend);
  const { landings = defaultLandings } = env.www;
  const { title = "", content = "" } = landings[props.blockName] || {};
  return title || content ? (
    <section className="bg-darkgrey">
      <div className={`mb-${props.marginTop}`} />
      <div className="-container py-1">
        <Col3Container>
          <div className="text-center">
            <div className="large-header">{ReactHtmlParser(title)}</div>
            <div className="standart-header content-html mt-3">{ReactHtmlParser(content)}</div>
          </div>
        </Col3Container>
      </div>
      <div className={`mb-${props.marginBottom}`} />
    </section>
  ) : null;
};

export const MissionSection = (props) => {
  const { env } = useSelector((state) => state.frontend);
  const { landings = defaultLandings } = env.www;
  const { title = "", content = "" } = landings?.mission || {};
  return title || content ? (
    <section className="bg-green">
      <a name="mission" />
      <div className="mb-5" />
      <div className="-container py-1">
        <Col3Container>
          <div className="text-center">
            <div className="large-header">{ReactHtmlParser(title)}</div>
            <div className="standart-header content-html mt-3">{ReactHtmlParser(content)}</div>
          </div>
        </Col3Container>
      </div>
    </section>
  ) : null;
};

export const ScoreSection = (props) => {
  const { env } = useSelector((state) => state.frontend);
  const { landings = {} } = env.www;
  let { avgScore } = env.www;
  // const translate = useTranslate();
  avgScore = _.round(avgScore, 1);
  const { title = "", content = "", fileId } = landings?.score || {};
  return avgScore ? (
    <section className="bg-green pt-5">
      <a name="score" />
      <div className="mb-4" />
      <div className="-container py-1">
        <Col3Container>
          <div className="text-center">
            <div className="large-header">
              {AvgScoreStars(avgScore)}
              {/* 
                <span className="mr-2">{avgScore}</span>
                {_.map(scores, (score) => {
                  let className;
                  if (avgScore > score - 0.5) {
                    className = "fas fa-star";
                  } else if (avgScore <= score - 0.5 && avgScore > score - 1) {
                    className = "fas fa-star-half-alt";
                  } else {
                    className = "fal fa-star";
                  }
                  return (
                    <i className={`${className} mr-1`} style={{ color: "gold" }} key={score} />
                  );
                })}
                 */}
            </div>
            {/* <div className="standart-header mt-3">{translate("users_scores")}</div> */}
            <div className="standart-header  mt-3">{ReactHtmlParser(content)}</div>
          </div>
        </Col3Container>
      </div>
      <div className="mb-5" />
    </section>
  ) : null;
};

export const FaqSection = (props) => {
  return (
    null && (
      <section className="bg-white">
        <a name="faq" />
        <div className="-container py-1">
          <Col3Container>
            <Faq {...props} />
          </Col3Container>
        </div>
      </section>
    )
  );
};

export const BlockSection = (props) => {
  const { env } = useSelector((state) => state.frontend);
  const { landings = {} } = env.www;
  const { title = "", content = "", fileId } = landings?.[props.blockName] || {};
  return title && content ? (
    <section className={`-bg-white ${props.className}`}>
      <a name={props.blockName} />
      <div className="-container">
        <Col3Container>
          <div className="text-center">
            <div className="large-header -text-dark">{ReactHtmlParser(title)}</div>
            <div className="standart-header -text-dark content-html  mt-3">
              {ReactHtmlParser(content)}
            </div>
            {fileId ? (
              <div className="mt-2">
                <img src={fileUrl(fileId)} width="100%" />
              </div>
            ) : null}
          </div>
          {props.children}
        </Col3Container>
      </div>
    </section>
  ) : null;
};

export const IntroSection = (props) => {
  const { env, items } = useSelector((state) => state.frontend);
  const { locales } = useSelector((state) => state);
  const [typewriterStrings, setTypewriterStrings] = useState([]);
  useEffect(() => {
    if (locales?.data?._id && _.size(items.categories)) {
      const strings = [];
      _.map(items.categories, (category) => {
        strings.push(getLocaledValue(category.localesName, locales.data?._id));
      });
      setTypewriterStrings(strings);
    }
  }, [items, locales]);
  const { landings = {}, settings = {} } = env.www;
  const { title = "", content = "" } = landings?.intro || {};

  const videoPlayer = (video) => {
    return String(video).search("http") === 0 ? (
      <div className="react-player">
        <ReactPlayer url={video} width="100%" controls={true} height="100%" />
      </div>
    ) : (
      ReactHtmlParser(video)
    );
  };
  return title || content ? (
    <section className="-bg-white pb-0 pt-3">
      <div className="-container p-0 m-0">
        <Col3Container>
          <div className="text-center">
            {/* 
              <div className="large-header">
                <Typewriter
                  options={{
                    strings: typewriterStrings,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
               */}
            <div className="large-header">{ReactHtmlParser(title)}</div>

            <div className="standart-header content-html mt-3">{ReactHtmlParser(content)}</div>
            {/* 
            {settings.introVideo && locales?.data?._id ? (
              <div className="mx-1 my-4">
                {videoPlayer(getLocaledValue(settings.introVideo, locales.data?._id))}
              </div>
            ) : null}
            */}
            {settings.videoFileId ? (
              <div className="react-player my-4">
                <ReactPlayer
                  url={fileUrl(settings.videoFileId, "stream")}
                  width="100%"
                  controls={true}
                  height="100%"
                />
              </div>
            ) : null}

            <PageButtons className="mt-5" />
          </div>
        </Col3Container>
      </div>
    </section>
  ) : null;
};

export const InfoSection = (props) => {
  const { env } = useSelector((state) => state.frontend);
  const { landings = {} } = env.www;
  const { title = "", content = "" } = landings?.info || {};
  return title && content ? (
    <section className="-bg-white">
      <div className="-container py-1">
        <Col3Container>
          <div className="text-center">
            <div className="large-header">{ReactHtmlParser(title)}</div>
            <div className="standart-header content-html mt-2">{ReactHtmlParser(content)}</div>
            {/* <StatsWidget /> */}
          </div>
        </Col3Container>
      </div>
    </section>
  ) : null;
};

export const ButtonSection = (props) => {
  return (
    <section className="bg-darkgrey p-0 m-0">
      <div className="-container p-0 m-0">
        <Col3Container>
          <div className="text-center pb-4">
            <PageButtons />
          </div>
        </Col3Container>
      </div>
    </section>
  );
};

export const ShopSection = (props) => {
  const translate = useTranslate();
  const { env } = useSelector((state) => state.frontend);
  const { landings = {}, settings = {} } = env.www;
  const { title = "", content = "", fileId } = landings?.shop || {};
  return title && content ? (
    <section className="-bg-white">
      <div className="-container pt-2">
        <Col3Container>
          <div className="text-center pb-0">
            <div className="large-header -text-dark">{ReactHtmlParser(title)}</div>
            <div className="standart-header -text-dark content-html mt-3">
              {ReactHtmlParser(content)}
            </div>
            {/* <StatsWidget /> */}
            {fileId ? (
              <div className="mt-2">
                <img src={fileUrl(fileId)} width="100%" className="rounded-xl" />
              </div>
            ) : null}

            <a href={settings.shopMerchUrl} target="_blank" className="btn btn-solid my-4">
              <i className="fa fa-tshirt mr-2" />
              <LocalesValues values={settings.shopMerchButton} default={translate("shop_merch")} />
            </a>
          </div>
        </Col3Container>
      </div>
    </section>
  ) : null;
};
