import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Col3Container from "components/standart/col3Container";
import { setBottomSpaceHeight } from "actions";

import ButtonsMenu from "./button_menu";

const BottomMenu = (props) => {
  const bottomMenuRef = useRef(null);
  const offset = 3;

  const [height, setHeight] = useState(offset);

  useEffect(() => {
    props.setBottomSpaceHeight(height);
  }, [height]);

  useEffect(() => {
    if (!bottomMenuRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      // console.log("changed height", bottomMenuRef.current?.clientHeight);
      setHeight(bottomMenuRef.current?.clientHeight);
      // Do what you want to do when the size of the element changes
    });
    resizeObserver.observe(bottomMenuRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  // useEffect(() => {
  //   console.log(bottomMenuRef.current?.clientHeight);
  // }, [bottomMenuRef.current]);

  return (
    <div
      className="position-fixed d-block mobile-menu"
      style={{ bottom: `-${offset}px`, zIndex: 1049 }}
      ref={bottomMenuRef}
    >
      <Col3Container>
        <ButtonsMenu />
      </Col3Container>
    </div>
  );

  return (
    <div className="w-100 h-100 position-absolute" style={{ top: 0, left: 0, overflowX: "hidden" }}>
      <div
        style={{
          color: "red",
          marginBottom: `${height - offset}px`,
        }}
      ></div>
      {/*  */}
      <div
        className="position-fixed d-block mobile-menu"
        style={{ bottom: `-${offset}px`, zIndex: 1049 }}
        ref={bottomMenuRef}
      >
        <Col3Container>
          <ButtonsMenu />
        </Col3Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  navi: state.navi,
});

export default connect(mapStateToProps, { setBottomSpaceHeight })(BottomMenu);
