import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import WebAppUserpic from "components/standart/webapp-userpic";
import { useDispatch, useSelector } from "react-redux";

import nl2br from "react-nl2br";
import { useTranslate } from "react-redux-multilingual";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";
import GiphyImage from "components/standart/giphyImage";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { customerApi } from "reducers/api";
import { LoadingIcon, unufiedNewLine } from "components/standart";
import MemeImage from "components/standart/memeImage";
import WebAppShareButton from "components/standart/webapp-share-button";
import { ExtraInfoContent } from "../extraInfoBlock";

export const openFriend = "openFriend";

export const ZodiacCustomerInfo = (friend) => {
  const translate = useTranslate();

  const { withPhoto } = friend;
  const [name] = _.split(friend.name, " ");
  const astroSign = translate(`zodiac_${friend.astroSign}`);

  return (
    <div className="d-flex align-items-center align-content-center">
      {withPhoto && friend.imageUrl ? (
        <div className="align-self-center mr-2">
          <WebAppUserpic src={friend.imageUrl} size={24} noPremium={true} />
        </div>
      ) : null}
      <div className="align-self-center text-nowrap">{name}</div>

      <div className="align-self-center -hashTag ml-1">
        <u>{astroSign}</u>
      </div>
    </div>
  );
};

export const FriendZodiac = (friend) => {
  const [openModal, setOpenModal] = useState(false);
  const translate = useTranslate();

  const toggleModal = () => setOpenModal(!openModal);
  const { settings } = useSelector((state) => state.frontend.env.www);

  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={friend.noModal ? null : toggleModal}>
        <ZodiacCustomerInfo {...friend} />
      </div>
      {openModal ? (
        <Modal
          size="lg"
          // toggle={toggleModal}
          centered={true}
          wrapClassName="fullscreen-modal"
          isOpen={true}
        >
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <ZodiacContent {...friend} />
            <div className="pt-5" />
            <div className="text-center">
              <ExtraInfoContent {...settings.merchInfo} icon="fa fa-tshirt" />
            </div>

            <div className="form-group text-center">
              <button type="button" className="btn btn-solid" onClick={toggleModal}>
                <i className="fa fa-chevron-left mr-2" />
                {translate("back")}
              </button>
            </div>
            <div className="py-5" />
            <div className="py-5" />
            <div className="py-5" />
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

const { api } = customerApi.actions;

const getZodiacInfo = api.get("/account/webapp/quotes-memes/astroSigns");

export const ZodiacContent = (friend) => {
  const dispatch = useDispatch();
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(null);
  const translate = useTranslate();
  const { locales } = useSelector((state) => state);

  useEffect(() => {
    dispatch(
      getZodiacInfo(
        { query: friend },
        {
          onRequest() {
            setInfo({});
            setLoading(true);
          },
          onFailure() {
            setLoading(false);
          },
          onSuccess(body) {
            setInfo(body);
            setLoading(false);
          },
        },
      ),
    );
  }, []);

  return (
    <div className="text-center">
      {loading ? <LoadingIcon /> : null}
      {info._id ? (
        <div>
          <div className="large-header">
            {translate("about_zodiac", { sign: translate(`zodiac_${info.astroSign}`) })}
          </div>

          <div className="pt-4" />
          <MemeImage url={info.memeImageUrl} />

          {/* 
          <div className="pt-4" />
          <div className="d-flex justify-content-center">
            <ZodiacCustomerInfo {...friend} />
          </div>
          */}

          <div className="pt-4" />
          {nl2br(unufiedNewLine(getLocaledValue(info.localesText, locales.data?._id)))}
          <div className="pt-4" />
          <WebAppShareButton share={"astroSign"} contentId={info._id} />
        </div>
      ) : null}
    </div>
  );
};

export const FriendsStateIcon = (gradientInfo) => {
  return (
    <span className="hashTag">
      {gradientInfo?.icon} <LocalesValues values={gradientInfo.localesName} />
    </span>
  );
};

export const FriendsNamesInfo = (props) => {
  const translate = useTranslate();
  const { customer, friend, customerSign } = props;
  return (
    <div className="d-flex justify-content-center align-items-center flex-row">
      <div className="-mx-auto">
        <FriendZodiac {...friend} withPhoto={true} />
      </div>
      <div className="mx-1"></div>
      {/* <div className="mx-2">{translate("and")}</div> */}
      <div className="-mx-auto">
        <FriendZodiac {...customer} withPhoto={true} />
      </div>
    </div>
  );
};

export const FriendsCommonOverlap = (props) => {
  const translate = useTranslate();
  const { overlapInfo } = props;
  return (
    <div>
      <div className="large-header mb-3">{translate("friends_common")}</div>
      <div className="form-group content-html">
        <LocalesValues values={overlapInfo?.localesText} isHtml={true} />
      </div>
    </div>
  );
};

export const FriendsCommonBlock = (props) => {
  const translate = useTranslate();

  const { data, customer, friend, overlapInfo } = props;
  return (
    <>
      <div className="large-header pb-3">{translate("friends_today")}</div>
      {_.size(data?.imageData?.data) ? (
        <div className="mb-3">
          <GiphyImage {...data.imageData} />
        </div>
      ) : null}
      <div className="mb-2">
        <FriendsNamesInfo {...{ customer, friend: friend.anotherCustomer || friend }} />
      </div>
      <div>
        <div className="content-html">
          {data?.gradientText ? (
            <LocalesValues
              values={data?.gradientText.localesText}
              isHtml={true}
              changeValueHandler={props.changeValueHandler}
            />
          ) : null}
        </div>
        <div className="mt-2 hashTag">
          <span>
            <FriendsStateIcon {...data?.gradientInfo} />
          </span>
        </div>
      </div>
    </>
  );
};
