import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import BirthDateCalendar from "components/standart/BirthDateCalendar";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { customerApi } from "reducers/api";
import { LoadingIcon, scrollTop } from "components/standart";
import Col3Container from "components/standart/col3Container";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";

import PremiumBlock from "../premium";
import { InviteButton } from "../invites";
import { GiftsBlock } from "../gifts";

const { api, session } = customerApi.actions;

const AddFriend = (props) => {
  const { currentSize, sessionStore } = props;
  const { translate, systemEnv, successHandler, navi } = props;
  const { settings } = props.env.www;

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [gender, setGender] = useState(null);
  const [birthDate, setBirthDate] = useState(null);

  const changeName = ({ target }) => setName(target.value);
  // const changeBirthDate = ({ target }) => setBirthDate(target.value);

  const save = () => {
    props.addFriend(
      { body: { name, gender, birthDate } },
      {
        onSuccess(body) {
          props.getUser(
            {},
            {
              onSuccess() {
                setOpenModal(false);
                setLoading(false);
                scrollTop(0);
                successHandler(body);
              },
              onFailure() {
                setLoading(false);
              },
            },
          );
        },
        onRequest() {
          setLoading(true);
        },
        onFailure() {
          setLoading(false);
        },
      },
    );
  };

  const [openModal, setOpenModal] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);
  useEffect(() => {
    setName("");
    setGender(null);
    setBirthDate(null);
  }, [openModal]);
  return (
    <div>
      {/* заглушка чтобы скрыть блок "добавить друзей руками" */}
      {false ? null : (
        <div>
          {
            /* currentSize < settings.freeFriends || */
            sessionStore.premium ? (
              <div>
                <div className="">
                  <div>
                    <button type="button" className="btn btn-solid" onClick={toggleModal}>
                      <i className="fa fa-theater-masks mr-2" />
                      {translate("friend_add_anonymous")}
                    </button>
                  </div>
                  {openModal && (
                    <Modal
                      centered
                      wrapClassName="fullscreen-modal"
                      isOpen={openModal}
                      size="md"
                      toggle={toggleModal}
                    >
                      <ModalHeader toggle={toggleModal}></ModalHeader>
                      <ModalBody>
                        <Col3Container>
                          {/* проверим, что текущее количество друзей входит в лимит, или у пользователя есть премиум */}
                          {
                            <div className="text-center">
                              <div className="form-group">
                                <label className="large-header">{translate("friend_name")}</label>
                                <input
                                  type="text"
                                  className="form-control text-center"
                                  value={name || ""}
                                  onChange={changeName}
                                />
                              </div>

                              <div className="form-group pt-4">
                                <label className="large-header">{translate("birthDate")}</label>
                                <BirthDateCalendar
                                  {...{ birthDate, setBirthDate }}
                                  hideYear={true}
                                />
                              </div>

                              {/* 
                              <div className="form-group pt-3 pb-2">
                                <label className="large-header">{translate("gender")}</label>
                                <div>
                                  {_.map(systemEnv.genders, (_gender, index) => {
                                    const isActive = gender === _gender;
                                    const activeClass = isActive ? "btn-dark" : "border-grey";
                                    return (
                                      <span
                                        key={index}
                                        className={`btn mr-2 mb-2 rounded ${activeClass}`}
                                        onClick={() => setGender(_gender)}
                                      >
                                        {translate(`gender_${_gender}_icon`)}{" "}
                                        {translate(`gender_${_gender}`)}
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                               */}

                              <div className="form-group text-center pt-4">
                                <button
                                  type="button"
                                  className="btn btn-solid"
                                  disabled={!(name && birthDate) || loading}
                                  onClick={save}
                                >
                                  {loading ? (
                                    <LoadingIcon className="mr-2" />
                                  ) : (
                                    <i className="fa fa-check mr-2" />
                                  )}
                                  {translate("friend_save")}
                                </button>
                              </div>
                            </div>
                          }
                        </Col3Container>
                        <BottomNaviSpacing />
                      </ModalBody>
                    </Modal>
                  )}
                </div>
              </div>
            ) : (
              <div className="position-relative">
                <PremiumBlock buttonIcon={"fa-lock"} buttonText={translate("friend_add_premium")} />
              </div>
            )
          }
        </div>
      )}
      <div className="pt-4" />
      <InviteButton />
    </div>
  );
};
const mapeStateToProps = (state) => ({
  systemEnv: state.customer.env,
  env: state.frontend.env,
  sessionStore: state.customer.session,
});

export default connect(mapeStateToProps, {
  ...session.actions,
  addFriend: api.post("/account/friends"),
})(withTranslate(AddFriend));
