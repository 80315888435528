import _ from "lodash";
import { RSAA } from "redux-api-middleware"; // RSAA = '@@redux-api-middleware/RSAA'
import { accessToken } from "./index";
import qs from "query-string";

export const { FRONTEND_API_URL } = process.env;

export const SUCCESS = "FRONTEND_API_URL_SUCCESS";
export const FAILURE = "FRONTEND_API_URL_FAILURE";
export const REQUEST = "FRONTEND_API_URL_REQUEST";

const { BUILD_VERSION } = process.env;

export default class {
  constructor(initState = {}) {
    this.states = {};
    this.actions = {};
    this.middlewares = {};
    this.url_states = {}; // стейты в контексте конкретной ссылки
    this.initState = initState;
  }

  get(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "GET", { success, failure, request }, this);
  }

  post(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "POST", { success, failure, request }, this);
  }

  patch(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "PATCH", { success, failure, request }, this);
  }

  put(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "PUT", { success, failure, request }, this);
  }

  auto(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "AUTO", { success, failure, request }, this);
  }

  upload(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "POST", { success, failure, request }, this, {
      upload: true,
    });
  }

  // создать редюсер, который в контексте своего объекта будет вызывать
  reducer() {
    const { states, initState } = this;
    return (state = initState, action) => {
      const type = String(action.type);
      const [method, key, uniq] = type.split("|");
      let result = state;
      if (_.isFunction(_.get(states, type))) {
        result = states[type](state, action.payload);
      }
      // если это окончательный вызов, то удалим связанные данные
      if ([SUCCESS, FAILURE].includes(key)) {
        delete states[[method, SUCCESS, uniq].join("|")];
        delete states[[method, FAILURE, uniq].join("|")];
        delete states[[method, REQUEST, uniq].join("|")];
      }
      return result;
    };
  }

  middleware() {
    const { states, actions, middlewares } = this;
    return (store) => (next) => (action) => {
      const result = next(action);
      const type = String(action.type);
      if (_.isFunction(_.get(middlewares, type))) {
        // тут может быть баг: если повторно вызван этот же запрос, пока другой не завершился
        // с указанием другой функции миддливара
        // вилка для того, чтобы вернуть в случае ошибки оригинальный response со стороны сервера
        const payload = _.get(action, "payload.response") || _.get(action, "payload");
        middlewares[type](payload);
      }
      // если это конечное действие в запуске, то удалим весь комплект связанных данных
      const [method, key, uniq] = type.split("|");
      if ([SUCCESS, FAILURE].includes(key)) {
        delete middlewares[[method, SUCCESS, uniq].join("|")];
        delete middlewares[[method, FAILURE, uniq].join("|")];
        delete middlewares[[method, REQUEST, uniq].join("|")];
      }
      return result;
    };
  }
}

export const defaults = {
  success(state) {
    return { ...state };
  },
  request(state) {
    return { ...state, _last_request: new Date() };
  },
  failure(state, result) {
    return {
      ...state,
      _last_error: _.get(result, "response.message") || _.get(result, "statusText"),
    };
  },
};

// генератор вызовов
export function createApi(url, method = "GET", props, reducer, options = {}) {
  const { success, failure, request } = _.omit(props);
  const { postfix = Math.random() } = options;
  // /*
  const ON_SUCCESS_KEY = `${method} ${url}|${SUCCESS}`;
  // reducer.states[ON_SUCCESS] = success || defaults.success;

  const ON_FAILURE_KEY = `${method} ${url}|${FAILURE}`;
  // reducer.states[ON_FAILURE] = failure || defaults.failure;

  const ON_REQUEST_KEY = `${method} ${url}|${REQUEST}`;
  // reducer.states[ON_REQUEST] = request || defaults.request;

  Object.assign(reducer.url_states, {
    [url]: { ON_REQUEST: ON_REQUEST_KEY, ON_SUCCESS: ON_SUCCESS_KEY, ON_FAILURE: ON_FAILURE_KEY },
  });
  // */

  const applyUrlParams = (url, params, query) => {
    // todo replace keys in url by params
    _.each(params, (value, key) => {
      url = url.replace(`{${key}}`, value);
    });
    return url + "?" + qs.stringify(query);
  };

  const isUpload = options.upload;

  return (req = {}, res = {}) => {
    const uniqId = Date.now() + "-" + Math.random();

    let ON_SUCCESS = `${ON_SUCCESS_KEY}|${uniqId}`;
    reducer.states[ON_SUCCESS] = success || defaults.success;

    let ON_FAILURE = `${ON_FAILURE_KEY}|${uniqId}`;
    reducer.states[ON_FAILURE] = failure || defaults.failure;

    let ON_REQUEST = `${ON_REQUEST_KEY}|${uniqId}`;
    reducer.states[ON_REQUEST] = request || defaults.request;

    const types = [ON_REQUEST, ON_SUCCESS, ON_FAILURE];

    const { body, query, params, headers = {} } = req;

    Object.assign(headers, {
      "API-Request-Id": uniqId,
      "Build-Version": BUILD_VERSION,
      // "CloClo-Context-Segment": CONTEXT_SEGMENT,
    });
    // если это не загрузка файла, то добавим фиксированный тип контента
    if (!isUpload) {
      headers["Content-Type"] = "application/json";
    }

    const token = localStorage.getItem(accessToken);
    if (token) {
      _.merge(headers, { Authorization: token });
    }
    let route = {};

    if (method === "AUTO") {
      route = _.get(window.treepay_routes, url);
      if (!route) {
        throw `no auto route ${url}`;
      }
    }
    // /*
    route.path && (url = route.path);
    route.method && (method = route.method);
    // */
    const endpoint = `${FRONTEND_API_URL}${applyUrlParams(url, params, query)}`;

    // вот тут следует
    reducer.middlewares[ON_SUCCESS] = res.onSuccess;
    reducer.middlewares[ON_FAILURE] = res.onFailure;
    reducer.middlewares[ON_REQUEST] = res.onRequest;
    // console.log (new Date, 'do rsaa', {endpoint, method, headers, ON_SUCCESS, 'mw': reducer.middlewares[ON_SUCCESS]});

    /*
        if (isUpload) {
            reducer.middlewares[ON_PROGRESS] = res.onProgress;
        }
        // */

    return {
      [RSAA]: {
        endpoint,
        headers,
        method,
        body: isUpload ? body : JSON.stringify(body),
        types,
        credentials: "include",
      },
    };
  };
}
